import type { NormalizedCacheObject } from "@apollo/client/core";
import { ApolloClient, ApolloLink, InMemoryCache, defaultDataIdFromObject } from "@apollo/client/core";
import { RetryLink } from "@apollo/client/link/retry";
import { getDataFromTree } from "@apollo/client/react/ssr";
import { injectAdditionalHeaders, setAuthorization } from "components/auth/Auth";
import createFundamenteiHttpLink from "lib/apollo/createFundamenteiHttpLink";
import createGitDetailsLink from "lib/apollo/createGitDetailsLink";
import { typePolicies } from "lib/apollo/typePolicies";
import { decodeInitialState } from "lib/internal/enigma/handlers";
import { decodeEnigmaticResponseLink } from "lib/internal/enigma/link";
import isBrowser from "lib/utils/isBrowser";
import withApollo from "next-with-apollo";
import PossibleTypes from "types/possibleTypes";

export default withApollo(
  ({ ctx, initialState }) => {
    return fromInitialState(decodeInitialState(initialState), [
      new RetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 7,
          retryIf: (error, operation) => {
            const isMutation = operation.query.definitions.some((candidate) => {
              return candidate.kind === "OperationDefinition" && candidate.operation === "mutation";
            });

            // Disable retries for mutations
            if (isMutation) {
              return false;
            }

            // Disable retries for "Bad Request" responses
            if (error.name === "ServerError" && typeof error.statusCode === "number" && error.statusCode === 400) {
              return false;
            }

            return !!error;
          },
        },
      }),
      injectAdditionalHeaders(ctx),
      setAuthorization(ctx),
      createGitDetailsLink(),
    ]);
  },
  {
    getDataFromTree,
  },
);

// eslint-disable-next-line default-param-last
export function fromInitialState(initialState = {}, links: ApolloLink[]): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    connectToDevTools: isBrowser(),
    ssrMode: true,
    link: ApolloLink.from([...links, decodeEnigmaticResponseLink, createFundamenteiHttpLink()]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies,
      possibleTypes: PossibleTypes.possibleTypes,
      dataIdFromObject: (responseObject) => {
        if (responseObject.__typename === "IssuerLogo" && responseObject.url) {
          return `${responseObject.__typename}:${responseObject.url}`;
        }
        // Skip caching via `objectId`
        if (responseObject.__typename === "FinancialsComparisonSheetAdversaryColumnIndicatorSeries") {
          return defaultDataIdFromObject(responseObject);
        }
        if (
          responseObject.__typename === "UserSettingsV2" &&
          responseObject.ownerId &&
          typeof responseObject.ownerId === "string"
        ) {
          return `${responseObject.__typename}:${responseObject.ownerId}`;
        }
        // TODO: Add better handling for new objects using typed identifiers
        if (
          (responseObject as Record<string, any>).objectId &&
          typeof (responseObject as Record<string, any>).objectId === "string"
        ) {
          return `${responseObject.__typename}:${responseObject.objectId}`;
        }
        return defaultDataIdFromObject(responseObject);
      },
    }).restore(initialState),
  });
}
