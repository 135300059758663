/* eslint-disable react/static-property-placement */
import base64url from "base64-url";
import { decode, encode } from "lib/internal/enigma/enigma";

export function encodeApolloState(data: Record<string, any>): string | null {
  const [encoded, encodedIntegrity] = encode(data);
  const [decoded, decodedIntegrity] = decode(encoded);
  if (decoded && decodedIntegrity === encodedIntegrity) {
    // return `)]}'${encoded}`;
    return `#${base64url.encode(encoded)}`;
  }
  return null;
}

export function decodeInitialState(initialState: any) {
  if (initialState && typeof initialState === "string") {
    // Handle obfuscated-only responses. They start with `)]}'`
    if (initialState.startsWith(")]}'")) {
      const [decoded] = decode(initialState.substring(4));
      return decoded;
    }

    // Handle compressed responses. They start with `#`
    if (initialState.startsWith("#")) {
      const [decoded] = decode(base64url.decode(initialState.substring(1)));
      return decoded;
    }
  }
  return initialState || {};
}
