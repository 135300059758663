// @ts-nocheck
/* eslint-disable */
import base64url from "base64-url";
import { decode } from "lib/internal/enigma/decoder.obfuscated";
import { isPlainObject } from "lodash";

export function decodeOperationResponse(response: Record<string, any>) {
  if (response && isPlainObject(response) && typeof response.data === "string") {
    const responseString = (response as Record<string, any>).data as unknown as string;

    // Handle obfuscated-only responses. They start with `)]}'`
    if (responseString.startsWith(")]}'")) {
      return {
        ...response,
        data: decode(responseString.substring(4)),
      };
    }

    // Handle compressed responses. They start with `#`
    if (responseString.startsWith("#")) {
      return {
        ...response,
        data: decode(base64url.decode(responseString.substring(1))),
      };
    }
  }
  return response;
}
