import getBaseUrl from "lib/utils/getBaseUrl";
import type { NextSeoProps } from "next-seo";
import { NextSeo } from "next-seo";
import { useIntl } from "react-intl";

export default function FundamenteiMetadata({ openGraph, title, description, ...props }: NextSeoProps) {
  const intl = useIntl();
  const isQualityAssurance = process.env.FUNDAMENTEI_ENV !== "production";
  const resolvedTitle = title || "Fundamentei";
  const resolvedDescription =
    description ||
    "Site completo de Análise Fundamentalista de Ações, Stocks e Fundos Imobiliários (FIIs). Indispensável para investidores Buy&Hold!";
  return (
    <NextSeo
      title={resolvedTitle}
      description={description || resolvedDescription}
      themeColor="#3F5FE9"
      openGraph={{
        type: "website",
        title: resolvedTitle,
        description: resolvedDescription,
        siteName: "Fundamentei",
        locale: intl.locale.replace("-", "_") || "pt_BR",
        ...openGraph,
        images:
          openGraph?.images && openGraph?.images.length > 0
            ? openGraph?.images
            : [
                {
                  url: isQualityAssurance
                    ? `${getBaseUrl()}/static/og/qa/1200w.png?v=20230529`
                    : `${getBaseUrl()}/static/og/1200w.png?v=20230529`,
                  width: 1200,
                  height: 630,
                  alt: "Fundamentei",
                },
              ],
        defaultImageWidth: 1200,
        defaultImageHeight: 630,
      }}
      twitter={{
        cardType: "summary_large_image",
        handle: "@fundamentei",
        ...props.twitter,
      }}
      {...props}
    />
  );
}
