/* eslint-disable no-underscore-dangle, react/no-danger, import/no-extraneous-dependencies */
import getBaseUrl from "lib/utils/getBaseUrl";
import Head from "next/head";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import { JsonLd } from "react-schemaorg";
import type { Graph } from "schema-dts";

export default function FundamenteiAppHead() {
  const intl = useIntl();
  const router = useRouter();
  const description = intl.formatMessage({
    defaultMessage:
      "Site completo de Análise Fundamentalista de Ações, Stocks e Fundos Imobiliários (FIIs). Indispensável para investidores Buy&Hold!",
    id: "fmQLUA",
  });
  const graph: Graph = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": `${getBaseUrl()}/#website`,
        url: getBaseUrl(),
        name: "Fundamentei",
        description,
        inLanguage: intl.locale,
        publisher: {
          "@id": `${getBaseUrl()}/#organization`,
        },
      },
      {
        "@type": "Organization",
        "@id": `${getBaseUrl()}/#organization`,
        name: "Fundamentei",
        url: getBaseUrl(),
        sameAs: [
          "https://instagram.com/fundamentei",
          "https://twitter.com/fundamentei",
          "https://medium.com/@fundamentei",
          "https://github.com/fundamentei",
          "https://www.youtube.com/channel/UC72WcGU4HpVposjNHlP3FRg",
          "https://www.linkedin.com/company/fundamentei",
        ],
        contactPoint: [
          {
            "@type": "ContactPoint",
            email: "contato@fundamentei.com",
            url: getBaseUrl(),
            contactType: "customer support",
            areaServed: ["BR", "US"],
            availableLanguage: ["Portuguese", "English"],
          },
        ],
      },
    ],
  };

  const fullUrl = `${getBaseUrl()}${router.asPath}`;
  // https://webmasters.stackexchange.com/questions/140553/use-of-canonical-tag-on-website-with-different-language-versions-and-separate-mo
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" key="viewport" />
      <meta name="robots" content="index,follow" />

      <JsonLd space={2} item={graph} />

      {/* I'm not 100% sure if that's right... */}
      {router.locale !== router.defaultLocale ? (
        <link rel="canonical" href={`${getBaseUrl()}/${router.locale}${router.asPath}`} />
      ) : (
        <link rel="canonical" href={`${getBaseUrl()}${router.asPath}`} />
      )}

      {/* Self-referencing hreflang */}
      <link rel="alternate" hrefLang="x-default" href={fullUrl} />
      {router.locale && router.defaultLocale && router.locale !== router.defaultLocale && false && (
        <link rel="alternate" hrefLang="pt-BR" href={fullUrl} />
      )}
      {router.locales?.map((locale) => {
        return (
          <link key={locale} rel="alternate" hrefLang={locale} href={`${getBaseUrl()}/${locale}${router.asPath}`} />
        );
      })}
    </Head>
  );
}
